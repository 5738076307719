<template>
  <v-dialog
    persistent
    v-model="dialogEditUser"
    :width="`${screenWidth}%`"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold">{{ texts.users.dialogEditUser.textTitle }}</p>
      <v-text-field
        v-model="userName"
        type="text"
        :label="texts.users.dialogEditUser.textUserName"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular"
        :placeholder="texts.users.dialogEditUser.textUserName"
        persistent-placeholder
        maxLength="40"
      ></v-text-field>
      <v-text-field
        v-model="userLastName"
        type="text"
        :label="texts.users.dialogEditUser.textUserLastName"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular mt-5"
        :placeholder="texts.users.dialogEditUser.textUserLastName"
        persistent-placeholder
        maxLength="40"
      ></v-text-field>
      <v-text-field
        v-model="userEmail"
        type="text"
        :label="texts.users.dialogEditUser.textUserEmail"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular mt-5"
        :placeholder="texts.users.dialogEditUser.textUserEmail"
        persistent-placeholder
        maxLength="85"
      ></v-text-field>
      <v-text-field
        v-model="userPosition"
        type="text"
        :label="texts.users.dialogEditUser.textUserPosition"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular mt-5"
        :placeholder="texts.users.dialogEditUser.textUserPosition"
        persistent-placeholder
        maxLength="40"
      ></v-text-field>
      <v-text-field
        v-model="userPhoneNumber"
        type="text"
        :label="texts.users.dialogEditUser.textUserPhoneNumber"
        outlined
        color="#000000"
        dense
        class="global-inputs mon-regular mt-5"
        :placeholder="texts.users.dialogEditUser.textUserPhoneNumber"
        persistent-placeholder
        maxLength="13"
        @keypress="soloNumbers"
      ></v-text-field>
      <div
        v-if="isResponsive"
        class="mt-9"
      >
        <v-btn
          @click="editUser"
          elevation="0"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
          style="width: 100%;"
        >
          {{ texts.users.dialogEditUser.textButtonAdd }}
        </v-btn>
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-secondary mon-regular mt-5"
          style="width: 100%;"
        >
          {{ texts.users.dialogEditUser.textButtonCancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center mt-9"
      >
        <v-btn
          @click="closeDialog"
          elevation="0"
          class="button-secondary mon-regular"
        >
          {{ texts.users.dialogEditUser.textButtonCancel }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="editUser"
          elevation="0"
          :loading="bLoading"
          :disabled="!validateForm"
          class="button-primary mon-bold"
        >
          {{ texts.users.dialogEditUser.textButtonAdd }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "UsersDialogEditUserLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      userName: "",
      userLastName: "",
      userEmail: "",
      userPosition: "",
      userPhoneNumber: "",
      screenWidth: 0,
      bLoading: false,
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    handleResize: function () {
      if (window.innerWidth >= 600) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogEditUser", {
        arr: [],
        active: false,
      });
      this.userName = "";
      this.userLastName = "";
      this.userEmail = "";
      this.userPosition = "";
      this.userPhoneNumber = "";
    },
    editUser: function () {
      this.bLoading = true;

      const payload = {
        sName: this.userName,
        sLastname: this.userLastName,
        sEmail: this.userEmail,
        sPhoneNumber: this.userPhoneNumber,
        sPositionName: this.userPosition,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/employees/${this.dialogEditUserArr.sVendorId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    // return create contact dialog state
    dialogEditUser() {
      return this.$store.state.dialogEditUser;
    },
    dialogEditUserArr: function () {
      return this.$store.state.dialogEditUserArr;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.userName !== "" &&
        this.userLastName !== "" &&
        this.userEmail !== "" &&
        this.userPosition !== "" &&
        this.userPhoneNumber !== "" &&
        this.userName !== null &&
        this.userLastName !== null &&
        this.userEmail !== null &&
        this.userPosition !== null &&
        this.userPhoneNumber !== null
      );
    },
  },
  watch: {
    dialogEditUser() {
      if (this.dialogEditUser) {
        this.userName = this.dialogEditUserArr.sName;
        this.userLastName = this.dialogEditUserArr.sLastname;
        this.userEmail = this.dialogEditUserArr.sEmail;
        this.userPosition = this.dialogEditUserArr.sPositionName;
        this.userPhoneNumber = this.dialogEditUserArr.sPhoneNumber;
        // this.texts = FILE.users[this.selectLanguage];
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        // this.texts = FILE.users[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}
</style>